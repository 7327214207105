<template>
  <div class="month-cont">
    <div class="flex month-nav-cont align-center">
      <ButtonVue
        class=""
        ref="prevMonth"
        label="<i class='material-icons'>arrow_back</i>"
        @btn-click="onClickPrev"
        type="outline"
        size="square" />
      <ButtonVue
        class=""
        ref="nextMonth"
        label="<i class='material-icons'>arrow_forward</i>"
        @btn-click="onClickNext"
        type="outline"
        size="square" />
      <ButtonVue
        class="btn-small"
        ref="today"
        label="Today"
        @btn-click="onClickToday"
        type="outline"
         />

      <div class="month-name">
        <h2>{{monthName}}</h2>
      </div>
    </div>

    <CalendarVue :year="year" :month="month"></CalendarVue>
  </div>
</template>

<script>
import CalendarVue from './Calendar'

import ButtonVue from '@Common/elements/ButtonVue'
import calendar from '@Common/utils/calendar_helpers'

import { useRouter } from 'vue-router'

export default {
  name: 'MonthVue',

  setup() {
    const router = useRouter()
    return {
      router,
    }
  },

  props: {
    month: Number,
    year: Number,
  },

  data() {
    return {
      today: new Date(),
    }
  },

  computed: {
    // get a 0 based indexed month number
    monthNum() {
      if (this.month) {
        return parseInt(this.month - 1)
      }
      return this.today.getMonth()
    },

    // 0 indexed months array for js
    monthName() {
      return calendar.MONTHS[this.monthNum].name
    },
  },

  methods: {
    onClickPrev() {
      let month = this.month - 1
      let year = this.year
      if (month === 0) {
        year -= 1
        month = 12
      }
      this.router.push({
        name: 'monthly',
        params: {
          month,
          year,
        }
      })
    },

    onClickNext() {
      let month = this.month + 1
      let year = this.year
      if (month === 13) {
        year += 1
        month = 1
      }
      this.router.push({
        name: 'monthly',
        params: {
          month,
          year,
        }
      })
    },

    onClickToday() {
      const month = this.today.getMonth() + 1 // 0 based indexing
      const year = this.today.getFullYear()
      this.router.push({
        name: 'monthly',
        params: {
          month,
          year,
        }
      })
    }
  },

  created() {
  },

  components:{
    ButtonVue,
    CalendarVue,
  }
}

</script>

<style lang="scss" scoped>
.month-cont {
  height: 100%;
  
  .month-nav-cont {
    padding: $padding;
    .btn-cont {
      padding-right: $padding;
    }

    .month-name {
      h2 {
        font-size: $font-large;
      }
    }
  }
}
</style>
