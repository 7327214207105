<template>
  <div class="modal-wrapper hide" ref="modal" :id="modalId">
    <div class="modal-cont" :class="size">
      <div class="modal-header">
        <slot name="modal-header">
          <span class="header-text">{{ headerText }}</span>
          <span class="close-cont">
            <i class="material-symbols-outlined close" @click="hide()">close</i>
          </span>
        </slot>
      </div>
      <div class="modal-body">
        <slot name="modal-body"></slot>
      </div>
      <div class="modal-footer">
        <slot name="modal-footer"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ModalVue',
  props: {
    id: String,
    headerText: String,

    size: {
      type: String,
      default: 'modal-med'
    }
  },

  data () {
    return {
      modalId: this.id,
    }
  },

  methods: {
    show() {
      this.$refs.modal.classList.remove('hide')
    },

    hide() {
      this.$refs.modal.classList.add('hide')
    }
  },

  created() {
    if (!this.id) {
      this.modalId = `modal-${this.$_.uniqueId()}`
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./../styles/modal";
</style>
