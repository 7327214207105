<template>
  <div class="purchase-list-wrapper"  @scroll="scrollCheck">
    <div class="loading-wrapper" v-if="loading">
      <LoadingWidget type="bar" ref="loading"></LoadingWidget>
    </div>

    <div v-else class="list-wrapper right">
      <ListCard
        v-for="purchase in purchaseList"
        :key="purchase.id"
        :model="purchase"
        :expandable="true"
      >
      </ListCard>
      <LoadingWidget type="bar" ref="loadingMore" class="hide"></LoadingWidget>
    </div>
  </div>
</template>

<script>
import ListCard from '@/components/lists/ListCard'
import LoadingWidget from '@Common/elements/LoadingWidget'
import { usePurchaseStore } from '@/stores/PurchaseStore'
import { useBusinessStore } from '@/stores/BusinessStore'
import { useCategoryStore } from '@/stores/CategoryStore'

export default {
  name: 'PurchaseList',

  setup() {
    const purchaseSet = usePurchaseStore()
    const businessSet = useBusinessStore()
    const categorySet = useCategoryStore()

    return {
      purchaseSet,
      businessSet,
      categorySet,
    }
  },

  data() {
    return {
      detailKeys: [
        'desc',
        {
          key: 'details',
          isLink: true,
        },
      ],
      loading: true,
    };
  },

  computed: {
    purchaseList() {
      return this.purchaseSet.getSet.map((p) => ({
        ...p,
        cardData: {
          left: this.$moment(p.date, 'YYYY-MM-DD').format('MM/DD/YYYY'),
          right: `$${p.amount}`,
          center: p.business && p.business.name,
          details: {
            header: p.category && p.category.name,
            text: this.$_.truncate(p.desc),
            link: {
              name: 'purchase',
              text: 'See Details',
              params: { id: p.id },
            },
          },
        },
      }));
    },

    businessList() {
      return this.businessSet.getSet.map(b => {
        return {
          ...b,
          cardData: {
            center: b.name
          }
        }
      })
    },

    categoryList() {
      return this.categorySet.getSet.map(c => {
        return {
          ...c,
          cardData: {
            center: c.name
          }
        }
      })
    },
  },

  methods: {
    scrollCheck(e) {
      const t = e.target
      if (t.scrollTop >= (t.scrollHeight - t.offsetHeight) && !!this.purchaseSet.getNextLink) {
        this.showLoading()
        this.purchaseSet.fetch(this.purchaseSet.getNextLink, true)
          .then(() => {
            this.hideLoading()
          })
      }
    },

    showLoading() {
      this.$refs.loadingMore.show()
    },

    hideLoading() {
      this.$refs.loadingMore.hide()
    },
  },

  created() {
    this.purchaseSet.fetch().then((r) => {
      this.loading = false;
      return r;
    });
  },

  components: {
    ListCard,
    LoadingWidget,
  },
};
</script>

<style lang="scss">
.purchase-list-wrapper {
  height: 100%;
  overflow: auto;

  .object-links {
    margin-top: $margin;

    .link {
      color: $color-primary-dark;
      font-weight: $bold;
      text-decoration: none;
      padding: $padding;
      margin: $margin;
      width: 100%;
      text-align: center;
      border: 2px solid transparent;

      &:hover, &.selected {
        border-color: $color-primary-dark;
      }
    }
  }
}
</style>
