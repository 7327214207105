<template>
  <div class="input-wrapper" :class="cssClasses"
    v-click-outside="hideSuggestions">
    <label v-show="!labelAsPlaceholder">{{inputLabel}}</label>

    <textarea 
      v-if="inputType === 'textarea'"
      class="input" 
      rows="5"
      :name="inputName" 
      ref="inputEl" 
      @keyup="updateValue">
    </textarea>
    <input 
      v-else
      class="input" 
      autocomplete="off"
      :name="inputName" 
      ref="inputEl" 
      @keyup="updateValue"
      @keyup.up="highlightSuggestion"
      @keyup.down="highlightSuggestion"
      @keyup.enter="selectSuggestion"
      :type="type"
      :step="inputStep"
      @focus="onFocusInput"
      :placeholder="labelAsPlaceholder ? placeholder : '' " />

    <div class="hide suggestions-list" ref="suggestionsList">
      <div
        v-for="(s,i) in suggestions"
        key="i">
        <div
          class="suggestion"
          :class="{selected:selected == i}"
          :data-option-id="s.id"
          @click="selectSuggestion(i)">
          {{s.name}}
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'InputVue',

  props: {
    modelValue: String,
    inputName: String,
    inputLabel: String,
    inputType: {
      type: String,
      default: 'text',
    },
    inputStep: String,
    focus: Boolean,
    cssClasses: String,
    options: [Array, Object],
    labelAsPlaceholder: {
      type: Boolean,
      default: false,
    },
    placeholder: String,
  },

  data() {
    return {
      suggestions: [],
      selected: -1,
      showPassword: false,
    }
  },

  computed: {
    type() {
      if (this.showPassword) return 'text'
      return this.inputType
    }
  },

  methods: {
    focusInput() {
      this.$refs.inputEl.focus()
    },

    onFocusInput(evt) {
      if (this.inputType === 'date') {
        evt.currentTarget.showPicker()
      }
    },

    showError() {
      this.$refs.inputEl.classList.add('error')
    },

    hideError() {
      this.$refs.inputEl.classList.remove('error')
    },

    updateValue(evt) {
      this.$emit('update:modelValue', evt.target.value)
      if (this.options && this.$_.size(this.options) > 0) {
        if (evt.target.value.length >= 2) {
          this.suggestions = []
          this.options.forEach(o => {
            if (o.name.toLowerCase().includes(evt.target.value.toLowerCase())) {
              this.suggestions.push(o)
            }
          })
          if (this.suggestions.length > 0) {
            this.showSuggestions()
          }
        } else if (evt.target.value.length === 0) {
          this.hideSuggestions()
        }
      }
    },

    highlightSuggestion(evt) {
      if (evt.key === 'ArrowDown' && this.selected !== this.suggestions.length - 1) {
        this.selected += 1
      } else if (evt.key === 'ArrowUp' && this.selected !== 0) {
        this.selected -= 1
      }
    },

    selectSuggestion(i) {
      if (this.options && this.$_.size(this.options) > 0) {
        let val = {}
        if (typeof i === 'number') val = this.suggestions[i]
        else val = this.suggestions[this.selected]
        this.$refs.inputEl.value = val.name
        this.hideSuggestions()
      }
    },

    showSuggestions() {
      this.$refs.suggestionsList.classList.remove('hide')
    },

    hideSuggestions() {
      this.$refs.suggestionsList.classList.add('hide')
      this.clearSuggestions()
    },

    clearSuggestions() {
      this.suggestions = []
      this.selected = -1
    },

    getValue() {
      return this.$refs.inputEl.value
    },

    togglePasswordType(show) {
      this.showPassword = show
    },

    reset() {
      this.$refs.inputEl.value = null
    },
  },
  
  created() {
  },
}
</script>

<style scoped lang="scss">
.input-wrapper {
  position: relative; 

  .input {
    border-style: solid;
    border-width: 1px;
  }

  .suggestions-list {
    width: 100%;
    position: absolute;
    top: 55px;
    left: 0;
    background-color: $white;
    border: 2px solid $dark-gray;
    z-index: 50;

    .suggestion {
      display: flex;
      padding: $padding-small;

      &.selected,
      &:hover {
        background-color: $highlight;
        color: $white;
      }
    }
  }
}
</style>
