const MONTHS = exports.MONTHS = [
  {name: 'January', days: 31},
  {name: 'February', days: 28},
  {name: 'March', days: 31},
  {name: 'April', days: 30},
  {name: 'May', days: 31},
  {name: 'June', days: 30},
  {name: 'July', days: 31},
  {name: 'August', days: 31},
  {name: 'September', days: 30},
  {name: 'October', days: 31},
  {name: 'November', days: 30},
  {name: 'December', days: 31}
]

const DAYS = exports.DAYS = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday'
]

exports.DAYS_INITIALS = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];

exports.isLeapYear = (y) => (y % 4 === 0) && (y % 100 !== 0 || y % 400 === 0)

exports.getMonth = (monthIndex) => MONTHS[monthIndex]
exports.getDay = (dayIndex) => DAYS[dayIndex]

exports.getDaysArray = (year, month, day) => {
  const date = new Date(year, month, day)
  const first = new Date(year, month)
  const last = new Date(year, month + 1, 0)
  const lastMonth = new Date(year, month, 0)

  let days = [];
  for (let i = first.getDay() - 1; i >= 0; i--) {
    days.push({
      date: lastMonth.getDate() - i,
      month: month - 1,
      cssClasses: 'last-month',
      data: [],
    })
  }

  for (let i = 0; i < last.getDate(); i++) {
    days.push({
      date: i + 1,
      month: month,
      cssClasses: (i === date.getDate()) ? 'today' : '',
      data: [] 
    })
  }

  for (let i = 0; i < (6 - last.getDay()); i++) {
    days.push({
      date: i + 1,
      month: month + 1,
      cssClasses: 'next-month',
      data: [],
    })
  }

  return days;
}
