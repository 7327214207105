<template>
  <div class="list-controls-cont">
    <div class="create-btns-cont flex">
      <CreateBtnAndModal />
      <PurchasesFileUploadVue />
    </div>

  </div>
</template>

<script>
import CreateBtnAndModal from '@/components/CreateBtnAndModal';
import PurchasesFileUploadVue from '@/components/exim/PurchasesFileUpload'

export default {
  name: 'ListControlVue',

  setup() {
  },

  data() {
    return {}
  },

  methods: {
  },

  created() {
  },

  components: {
    CreateBtnAndModal,
    PurchasesFileUploadVue,
  }
}
</script>

<style lang="scss">
.create-btns-cont { 
  .create-btn-and-modal-cont {
    margin-right: $margin-med;
  }
}
</style>
