<template>
  <div class="object-list-wrapper">
    <div class="list-cont">
      <div class="list-header left centered">
        <ListControlsVue />

        <div class="flex flex-column align-center object-links">
          <router-link :to="{ name: 'purchases' }" class="link" :class="{selected: objectSet === 'purchases'}">
            Purchases
          </router-link>
          <router-link :to="{ name: 'businesses' }" class="link" :class="{selected: objectSet === 'businesses'}">
            Businesses
          </router-link>
          <router-link :to="{ name: 'categories' }" class="link" :class="{selected: objectSet === 'categories'}">
            Categories
          </router-link>
        </div>
      </div>

      <component :is="objectView" />
    </div>
  </div>
</template>

<script>
import { markRaw } from 'vue'

import ListCard from '@/components/lists/ListCard'
import ListControlsVue from '@/components/lists/ListControls'
import BusinessItem from '@/components/lists/BusinessItem'
import BusinessList from '@/components/lists/BusinessList'
import CategoryItem from '@/components/lists/CategoryItem'
import CategoryList from '@/components/lists/CategoryList'
import PurchaseItem from '@/components/lists/PurchaseItem'
import PurchaseList from '@/components/lists/PurchaseList'

export default {
  name: 'ObjectList',

  setup() {
    return {
    }
  },

  data() {
    return {
      detailKeys: [
        'desc',
        {
          key: 'details',
          isLink: true,
        },
      ],
      objectSet: '',
      objectView: '',
    };
  },

  computed: {

  },

  methods: {
  },

  created() {
    this.objectSet = this.$route.name
    switch (this.objectSet) {
      case 'purchases':
        this.objectView = markRaw(PurchaseList)
        break
      case 'purchase':
        this.objectView = markRaw(PurchaseItem)
        break
      case 'businesses':
        this.objectView = markRaw(BusinessList)
        break
      case 'business':
        this.objectView = markRaw(BusinessItem)
        break
      case 'categories':
        this.objectView = markRaw(CategoryList)
        break
      case 'category':
        this.objectView = markRaw(CategoryItem)
        break
      default:
        this.objectView = markRaw(PurchaseList)
        break
    }
  },

  components: {
    ListCard,
    ListControlsVue,
    BusinessList,
    CategoryList,
    PurchaseList,
  },
};
</script>

<style lang="scss">
.object-list-wrapper {
  height: 100%;

  .object-links {
    margin-top: $margin;

    .link {
      color: $color-primary-dark;
      font-weight: $bold;
      text-decoration: none;
      padding: $padding;
      margin: $margin;
      width: 100%;
      text-align: center;
      border: 2px solid transparent;

      &:hover, &.selected {
        border-color: $color-primary-dark;
      }
    }
  }


}
</style>
