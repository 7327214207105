<template>
  <div class="business-item-cont flex" ref="root">
    <LoadingWidget v-show="loading" type="bar" ref="loading"></LoadingWidget>

    <div v-show="!loading">
      <div class="back-btn-cont">
        <ButtonVue
          label="Back"
          @btn-click="$router.go(-1)">
        </ButtonVue>
      </div>

      <div class="business-details" ref="detailsCont">
        <div class="business">
          <div class="name primary-text large-text">{{ business?.name }}</div>
          <div class="amount primary-text">${{ business?.spent }}</div>
          <div class="default-category">{{ business?.default_cat }}</div>
        </div>

        <div class="recent-purchases flex flex-column">
          <div 
            v-for="purchase in business.recent"
            :key="purchase.id"
            class="recent-purchase flex">
            <span class="date">{{ purchase.date }}</span>
            <span class="category">{{ purchase.category?.name }}</span>
            <span class="amount">${{ purchase.amount }}</span>
          </div>
        </div>

        <div class="history-chart-wrapper">
          <div class="chart-cont" ref="chartCont"></div>
        </div>
      </div>
      
      <div class="error-cont hide" ref="errorCont">
      </div>
    </div>

  </div>
</template>

<script>
import ButtonVue from '@Common/elements/ButtonVue'
import LoadingWidget from '@Common/elements/LoadingWidget'

import { groupedBarChart } from '@/charts/BarChart'
import { axGet } from '@/utils/fetch_helpers'

const BUSINESS_URL = `${process.env.VUE_APP_PIPES}/finances/businesses/`

export default {
  name: 'BusinessItem',

  props: {
  },

  data () {
    return {
      id: this.$route.params.id,
      business: {
        name: '',
        spent: 0,
        default_cat: '',
        recent: [{}], //TODO format date
        history: [],
      },
      history: [],
      loading: true,
    }
  },

  methods: {
    fetchBusiness() {
      return axGet(`${BUSINESS_URL}${this.id}/`).then(
        res => {
          this.showDetails(res)
        },
        err => {
          console.log('error', err)
          this.showError(err)
        }
      )
    },

    showDetails(r) {
      this.business = r
      this.loading = false 
      this.history = this.$_.map(this.business.history, year => this.$_.map(year, month => month)).flat()
      this.createChart()
    },

    createChart() {
      const data = this.history
      console.log(data)

      const chart = groupedBarChart({
        data,
        cont: '.chart-cont',
        groupByKey: 'month',
        groupedKey: 'year',
        valueKey: 'total',
        width: this.$refs.root.clientWidth,
        height: 500,
      })

      this.$refs.chartCont.append(chart)
    },

    showError(err) {
      this.$refs.errorCont.innerText = err
      this.loading = false
    },
  },

  created() {
  },

  mounted() {
    this.fetchBusiness()
  },

  components: {
    ButtonVue,
    LoadingWidget,
  },
}
</script>

<style lang="scss" scoped>
.business-item-cont {
  margin: $margin;

  .business-details {
    margin: $margin 0;

    .recent-purchases {
      margin: $margin 0;
      width: 50%;

      .date {
        margin-right: $margin;
      }

      .amount {
        margin-left: auto;
      }
    }
  }
}
</style>
